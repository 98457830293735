.uploadDocumentUtills {
  margin-top: 3.24vh;
  width: 80%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  .uploadResult {
    display: flex;
    align-items: center;
    width: 100%;
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: right;
    opacity: 1;
    position: relative;

    span {
      margin-right: 2.97%;
    }

    .tryUploadAgain {
      text-decoration: var(--decoration-underline);
      font: var(--font-style-normal) normal var(--font-weight-normal)
        var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: right;
      text-decoration: underline;
      color: var(--color-primary);
      opacity: 1;
      position: absolute;
      left: 0;
      right: auto;
      display: flex;
      align-items: center;
    }

    .successeded {
      display: flex;
      position: absolute;
      left: 0;
      right: auto;
    }

    img {
      opacity: 1;
      height: 1.42rem;
      width: 1.42rem;
    }

    .successIcon,
    .failureIcon {
      margin-right: 0.5rem;
    }
  }

  .uploadDocumentImage {
    margin-top: 1.124vh;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 0.58;
  }

  .addDocument {
    display: flex;
    margin-top: 3.24vh;

    .uploadDocumentLink {
      display: flex;
      align-items: center;
      text-decoration: var(--decoration-underline);
      font: var(--font-style-normal) normal var(--font-weight-normal)
        var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: right;
      text-decoration: underline;
      color: var(--color-primary);
      opacity: 1;
      cursor: pointer;

      .addFileIcon {
        padding-left: 6.94px;
      }
    }

    .uploadDocumentInput {
      display: none;
    }
  }

  .allowedDocumentFormats {
    margin-top: 0.74vh;
    font: var(--font-style-normal) normal var(--font-weight-normal) 11px/22px
      var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-align: right;
    color: var(--color-inactive);
    opacity: 1;
  }

  .deleteDocument {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}
