body {
  background-color: var(--color-label);
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  font-size: 14px;
}

.signatureBox {
  display: block;
  height: 100%;
  width: 100%;
}

.sigContainer {
  width: 100%;
  height: 80%;
  margin: 0 auto;
  background-color: var(--color-white);
  position: relative;
}

.sigPad {
  width: 100%;
  height: 100%;
  font-size: 1rem;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 14.285rem;
  height: 3.57rem;
  background-color: var(--color-white);
}

.signautureControls {
  display: flex;
  width: 28.5rem;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3rem;
  position: relative;

  @media only screen and (max-width: 600px) {
    width: auto;
  }

  .acceptButton {
    background: var(--color-action) 0% 0% no-repeat padding-box;
    opacity: 1;
    font: var(--font-style-normal) normal var(--font-weight-bold)
      var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-align: center;
    color: var(--color-white);
    outline: none;
    border: none;
    width: 7.5rem;
    height: 2.57rem;
    padding: 0;
  }

  .clearButton {
    text-decoration: var(--decoration-underline);
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-16) / 22px var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: right;
    text-decoration: underline;
    opacity: 1;
    outline: none;
    border: none;
    background-color: transparent;
    height: 1.57rem;
    width: 1.85rem;
  }

  img {
    margin-right: 1rem;
  }
}

.separationLine {
  border-top: 1px solid var(--color-label);
  opacity: 1;
  width: 28.5rem;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: -1.62rem;
}

.closeModal {
  position: absolute;
  border: none;
  background-color: transparent;
  top: 1rem;
  right: 1rem;
  outline: none;
  cursor: pointer;
}

