.finishProcessPage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: right;
  padding-right: 10.52%;

  @media only screen and (max-width: 600px) {
    padding-right: 0;
    align-items: center;
  }

  .finishProcessAndDeliveryText {
    margin-top: 5.86vh;
    font: var(--font-style-normal) normal var(--font-weight-bold) 30px /
      var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: right;
    opacity: 1;

    @media only screen and (max-width: 600px) {
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-16) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
    }
  }
}
