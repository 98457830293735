.qustionsAndAnswersWrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 42.65%;
  height: 100vh;
  background-color: white;
  overflow-y: scroll;
  border-radius: 2%;
  margin-bottom: 2rem;
  animation-duration: 0.5s;
  animation-name: slidein;

  @keyframes slidein {
    from {
      left: -50%;
    }

    to {
      left: 0;
    }
  }

  &.closingAnimation {
    left: -50%;

    animation-duration: 0.5s;
    animation-name: slideout;

    @keyframes slideout {
      from {
        left: 0;
      }

      to {
        left: -50%;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    z-index: 1000;

    @keyframes slidein {
      from {
        top: 100vh;
      }

      to {
        top: 0;
      }
    }

    &.closingAnimation {
      top: 100vh;
      left: 0;

      @keyframes slideout {
        from {
          top: 0;
        }

        to {
          top: 100vh;
        }
      }
    }
  }

  .closeQAndA {
    margin-top: 2.12rem;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: right;
    display: flex;
    margin-right: 2.375rem;
    color: #909090 0% 0% no-repeat padding-box;
    opacity: 1;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      margin-right: 1.5rem;
    }
  }

  .qAndAHeaderText {
    font: var(--font-style-normal) normal var(--font-weight-bold)
      var(--font-size-30) / var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-align: center;
    color: var(--color-label);
    opacity: 1;
    display: flex;
    text-align: right;
    margin-right: 3.571rem;
    margin-top: 2.955rem;

    @media only screen and (max-width: 600px) {
      margin-right: 1.5rem;
    }
  }

  .qAndAContent {
    margin-right: 3.571rem;
    margin-top: 3.298rem;

    @media only screen and (max-width: 600px) {
      margin-right: 1.5rem;
    }
  }
}
