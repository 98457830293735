.qAndA {
  .question {
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-14) / 25px var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-align: right;
    color: var(--color-primary);
    opacity: 1;
    padding: 0;
  }

  .qHeader {
    font: var(--font-style-normal) normal var(--font-weight-bold)
      var(--font-size-14) / 25px var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-align: right;
    color: var(--color-primary);
    opacity: 1;
  }

  .aHeader {
    font: italic normal var(--font-weight-bold) var(--font-size-14) / 25px
      var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-align: right;
    color: var(--color-label);
    opacity: 1;
  }

  .answer {
    font: italic normal var(--font-weight-normal) var(--font-size-14) / 25px
      var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-align: right;
    color: var(--color-label);
    opacity: 1;
    padding: 0;
  }
}
