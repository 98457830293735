.finishProcessForm {
  width: 54.48%;

  @media only screen and (max-width: 600px) {
    width: auto;
    margin: 0 2.57rem;
  }

  .deliveryAndConsultation {
    margin-top: 3.94vh;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
      margin-top: 1.5rem;
      flex-wrap: wrap;

      .deliveryTime {
        display: flex;
        flex-direction: row;

        .chooseDelivery {
          margin-right: 1.714rem;
        }

        @media only screen and (max-width: 600px) {
          div {
            display: flex;
            align-items: center;

            input {
              margin-right: 1rem;
            }

            label {
              font: var(--font-style-normal) normal var(--font-weight-normal)
                12px / var(--line-spacing-30) var(--font-family-primary);
              letter-spacing: var(--character-spacing-0);
              text-align: right;
            }
          }

          .delivery {
            @media only screen and (max-width: 600px) {
              font: var(--font-style-normal) normal var(--font-weight-bold)
                var(--font-size-12) / var(--line-spacing-30)
                var(--font-family-primary);
              letter-spacing: var(--character-spacing-0);
            }
          }
        }
      }
    }

    label {
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      color: var(--color-label);
      text-align: right;
      opacity: 1;

      @media only screen and (max-width: 600px) {
        font: var(--font-style-normal) normal var(--font-weight-bold)
          var(--font-size-12) / var(--line-spacing-30)
          var(--font-family-primary);
        letter-spacing: var(--character-spacing-0);
      }
    }

    .checkboxContainer {
      position: relative;
      left: 2rem;

      @media only screen and (max-width: 600px) {
      }
    }
    .advice {
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: right;
      color: var(--color-primary);
      opacity: 1;
      width: 10rem;

      @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        margin-top: 1rem;

        div {
          height: 2.285rem;
          width: 9.35rem;

          button {
            font: var(--font-style-normal) normal var(--font-weight-bold)
              var(--font-size-10) / var(--font-size-25)
              var(--font-family-primary);
            letter-spacing: var(--character-spacing-0);
          }
        }
      }
    }
  }

  .addOnTextarea {
    margin-top: 3.14rem;
  }

  .agreeToTerms {
    margin-top: 9.38vh;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: space-between;

    @media only screen and (max-width: 600px) {
      flex-wrap: wrap;

      .digitalSignatureInput {
        margin-top: 3.95rem;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .agreeToCommercial {
      margin-bottom: 0;
      margin-top: 0.5rem;
    }

    label {
      margin-right: 0.5rem;
      font: var(--font-style-normal) normal var(--font-weight-normal) 12px /
        var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      color: var(--color-label);
      text-align: right;
      opacity: 1;
    }
  }

  .varieties {
    margin-top: 4rem;
  }

  .registerOrBack {
    margin-top: 7.85rem;
    display: flex;
    justify-content: space-between;
    width: 150%;
    align-items: center;

    @media only screen and (max-width: 600px) {
      width: auto;
      margin-top: 3.07rem;
    }

    .registerButton {
      background: var(--color-action) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      opacity: 1;
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: left;
      color: var(--color-white);
      width: 10.92rem;
      height: 3.14rem;

      @media only screen and (max-width: 600px) {
        height: 2.57rem;
        width: 8.785rem;
        font: var(--font-style-normal) normal var(--font-weight-bold)
          var(--font-size-14) / var(--line-spacing-14)
          var(--font-family-primary);
        letter-spacing: var(--character-spacing-0);
        text-align: left;
        opacity: 1;
      }
    }

    .back {
      text-decoration: var(--decoration-underline);
      font: var(--font-style-normal) normal var(--font-weight-normal)
        var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      color: var(--color-label);
      text-align: left;
      text-decoration: underline;
      opacity: 1;

      @media only screen and (max-width: 600px) {
        font: var(--font-style-normal) normal var(--font-weight-normal)
          var(--font-size-12) / var(--line-spacing-14)
          var(--font-family-primary);
      }
    }
  }

  .deliveryTime {
    display: flex;
    flex-direction: column;

    .delivery {
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      color: var(--color-label);
      text-align: right;
      opacity: 1;
    }
  }

  .chooseDelivery {
    display: flex;

    .morning,
    .noon,
    .evening {
      background: transparent url("../../../../assets/images/checkboxIcon.svg");
    }

    .noon,
    .evening {
      margin-right: 2.2rem;
      background-color: var(--color-primary);
      opacity: 1;

      &:focus,
      :active {
        color: var(--color-primary);
        background-color: var(--color-primary);
      }
    }

    label {
      font: var(--font-style-normal) normal var(--font-weight-normal)
        var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      color: var(--color-label);
      text-align: right;
      opacity: 1;
      margin-right: 0.2rem;
    }

    input {
      background: transparent url("../../../../assets/images/checkboxIcon.svg")
        0% 0% no-repeat padding-box;
      opacity: 1;

      &:focus {
        background: transparent
          url("../../../../assets/images/checkboxIcon.svg");
      }
    }
  }

  .isPolicyAndUsageAccepted {
    label {
      margin-right: 0.2rem;
      font: var(--font-style-normal) var(--font-weight-normal)
        var(--font-size-14) / var(--line-spacing-12) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: right;
      opacity: 1;
    }

    .linkToPolicy {
      text-decoration: var(--decoration-underline);
      font: var(--font-style-normal) normal var(--font-weight-normal)
        var(--font-size-14) / var(--line-spacing-14) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: right;
      color: var(--color-primary);
      opacity: 1;
      margin-right: 0.214rem;
      cursor: pointer;
      outline: none;
      background-color: transparent;
      border: none;
    }
  }
}
