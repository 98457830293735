.verification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .verificationText {
    font: var(--font-style-normal) normal var(--font-weight-bold) 30px /
      var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: center;
    opacity: 1;
    margin-top: 18.6vh;

    @media only screen and (max-width: 600px) {
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-20) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(-character-spacing-0);
      color: var(--unnamed-color-4d4d4d);
      text-align: center;
      opacity: 1;
    }
  }

  .verificationCodeExplenationText {
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-16) / 25px var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: center;
    opacity: 1;
    margin-top: 1.191vh;
    width: 29.42rem;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .resendCodeText {
    text-decoration: var(--decoration-underline);
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-16) / var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-align: left;
    text-decoration: underline;
    color: var(--color-primary);
    opacity: 1;
    margin-top: 3.32vh;
  }

  .previousPage {
    text-decoration: var(--decoration-underline);
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: left;
    text-decoration: underline;
    opacity: 1;
    margin-top: 10.1vh;
  }
}
