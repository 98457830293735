.dateInput {
  // margin-bottom: 14px;
  margin-bottom: 1rem;
  position: relative;

  label {
    display: block;
    margin-bottom: 2px;
    font: var(--font-style-normal) normal var(--font-weight-bold)
      var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    opacity: 1;

    .required {
      display: inline;
      margin-right: 4px;
      font: var(--font-style-normal) normal var(--font-weight-normal)
        var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: right;
      color: var(--color-action);
      opacity: 1;
    }
  }

  &:focus-within label {
    color: var(--color-primary);
  }

  input {
    background: var(--color-white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--color-inactive);
    border-radius: 3px;
    opacity: 1;
    width: 100%;
    padding: 0.75rem;
    background-image: url("../../../assets/images/IconCalendar.svg");
    background-position-x: calc(100% - 0.75rem);
    background-position-y: center;
    text-align: center;
    box-sizing: content-box;
    height: 1rem;
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);

    &:focus {
      border-color: var(--color-primary);
    }
    .calendarIcon {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }

  .rtlOverlay {
    left: auto;
    right: 0;
    top: 2.75rem;
    z-index: 2;
  }

  .errorMessage {
    font: var(--font-style-normal) normal var(--font-weight-normal) 12px/22px
      var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-align: right;
    color: var(--color-white);
    opacity: 1;
    position: absolute;
    left: 10px;
    bottom: 8px;
    font-size: 14px;
    font-weight: normal;

    .errorIcon {
      display: block;
      background-color: var(--color-action);
      border-radius: 50%;
      width: 14px;
      height: 14px;
      text-align: center;
      line-height: 14px;
      cursor: pointer;
    }
  }

  &.hasError {
    input {
      border-color: var(--color-action);
    }
  }
}
