.MuiPaper-root {
  display: flex;
  box-shadow: none;
}

.PrivateTabIndicator-colorPrimary-4 {
  background-color: var(--color-primary) !important;
  height: 0.5rem;
  left: 0;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: var(--color-primary) !important;
}

.MuiTouchRipple-root {
  color: var(--color-primary) !important;
}

.policyAndUseTexts {
  padding: 1rem 2rem;
}

.closeModalButton {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  position: relative;
  top: 1rem;
  // right: 1rem;
  float: left;
  margin-left: 1rem;
}
