.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.modal-main {
  position: fixed;
  background: var(--color-white);
  width: 33.64rem;
  height: 26.42rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.fullPage {
    height: 57.428rem;
    width: 47.07rem;
    overflow-y: scroll;

    @media only screen and (max-width: 600px) {
      width: 28rem;
    }
  }

  @media only screen and (max-width: 600px) {
    width: 28rem;
  }
}

.modal-notify {
  position: fixed;
  background: var(--color-white);
  width: 33.64rem;
  height: 17.35rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
