.digitalSignatureInput {
  display: flex;
  flex-direction: column;
  position: relative;

  .signatureModal {
    margin-bottom: 1rem;
    background-color: lightgray;
    border: 1px solid grey;
    cursor: pointer;
    font: var(--font-style-normal) normal var(--font-weight-bold)
      var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: right;
    opacity: 1;
    outline: none;
  }
}
