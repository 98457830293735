.registerPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .registerGreetingText {
    font: var(--font-style-normal) normal var(--font-weight-bold) 30px /
      var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: center;
    opacity: 1;
    margin-top: 18.6vh;

    @media only screen and (max-width: 600px) {
      font: var(--font-style-normal) var(--font-weight-bold) var(--font-size-20) /
        var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      color: var(--unnamed-color-4d4d4d);
      text-align: center;
      opacity: 1;
    }
  }
}
