.uploadDocuments {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: right;
  padding-right: 10.52%;

  @media only screen and (max-width: 600px) {
    align-items: center;
    padding-left: 10.52%;
    text-align: center;
  }

  .uploadDocumentsText {
    margin-top: 5.86vh;
    font: var(--font-style-normal) normal var(--font-weight-bold) 30px /
      var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: right;
    opacity: 1;

    @media only screen and (max-width: 600px) {
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-16) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      color: var(--unnamed-color-4d4d4d);
      text-align: right;
      opacity: 1;
    }
  }

  .uploadDocumentsExplanationText {
    margin-top: 1.11vh;
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-14) / 22px var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: right;
    opacity: 1;
    width: 29.71rem;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .uploadDocumentUtills {
    margin-top: 3.24vh;

    .addDocument {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 600px) {
        justify-content: center;
      }

      .uploadDocumentLink {
        text-decoration: var(--decoration-underline);
        font: var(--font-style-normal) normal var(--font-weight-normal)
          var(--font-size-16) / var(--line-spacing-14)
          var(--font-family-primary);
        letter-spacing: var(--character-spacing-0);
        text-align: right;
        text-decoration: underline;
        color: var(--color-primary);
        opacity: 1;

        @media only screen and (max-width: 600px) {
          text-decoration: var(--decoration-underline);
          font: var(--font-style-normal) normal var(--font-weight-normal)
            var(--font-size-14) / var(--line-spacing-14)
            var(--font-family-primary);
          letter-spacing: var(--character-spacing-0);
          text-align: right;
          opacity: 1;
        }
      }
    }

    .allowedDocumentFormats {
      margin-top: 0.74vh;
      font: var(--font-style-normal) normal var(--font-weight-normal) 11px/22px
        var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: right;
      color: var(--color-inactive);
      opacity: 1;

      @media only screen and (max-width: 600px) {
        text-align: center;
      }
    }
  }

  .actions {
    margin-top: 7.57vh;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }

    .submitOrBack {
      width: 10.92rem;
      background: var(--color-action) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border: none;
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: center;
      color: var(--color-white);
      opacity: 1;
      padding-top: 15px;
      padding-bottom: 15px;
      text-decoration: none;
      cursor: pointer;

      @media only screen and (max-width: 600px) {
        height: 2.57rem;
        width: 8.785rem;
        font: var(--font-style-normal) normal var(--font-weight-bold)
          var(--font-size-14) / var(--line-spacing-14)
          var(--font-family-primary);
        letter-spacing: var(--character-spacing-0);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        background: var(--color-action) 0% 0% no-repeat padding-box;
      }
      &:disabled {
        background: var(--color-action-inactive);
      }
    }

    .back {
      text-decoration: var(--decoration-underline);
      font: var(--font-style-normal) normal var(--font-weight-normal)
        var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      color: var(--color-label);
      text-align: left;
      text-decoration: underline;
      opacity: 1;

      @media only screen and (max-width: 600px) {
        text-decoration: var(--decoration-underline);
        font: var(--font-style-normal) normal var(--font-weight-normal)
          var(--font-size-12) / var(--line-spacing-14)
          var(--font-family-primary);
        letter-spacing: var(--character-spacing-0);
        color: var(--unnamed-color-4d4d4d);
        text-align: left;
      }
    }
  }

  .uploadDocumentsSeparationLine {
    width: 75.75%;
    border-top: 1px solid var(--color-separator);
    opacity: 1;
    margin-top: 2.5vh;
  }
}
