.progressBar {
  .progressDetials {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: right;

    .uploadPercent {
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: right;
      color: var(--color-primary);
      opacity: 1;
    }
  }

  .barContainer {
    background: #eaeaea 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    position: relative;
    width: 100%;
    height: 7px;
    margin-bottom: 14.28vh;

    .progressInBar {
      background: var(--color-primary) 0% 0% no-repeat padding-box;
      border-radius: 18px;
      opacity: 1;
      position: absolute;
      right: 0;
      height: 7px;
    }
  }
}
