.homePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .homeWelcomeText {
    font: var(--font-style-normal) var(--font-weight-bold) var(--font-size-40) /
      var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-align: center;
    color: var(--color-primary);
    opacity: 1;
    margin-top: 11.68rem;
    height: 3.92rem;

    @media only screen and (max-width: 600px) {
      font: var(--font-style-normal) var(--font-weight-bold) var(--font-size-20) /
        var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: center;
      opacity: 1;
      margin-top: 3.82rem;
      height: 1.92rem;
    }
  }

  .homeSloganText {
    font: var(--font-style-normal) normal var(--font-weight-normal) 25px/25px
      var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: center;
    opacity: 1;
    margin-top: 0.727rem;
    height: 2.64rem;

    @media only screen and (max-width: 600px) {
      font: var(--font-style-normal) var(--font-weight-normal)
        var(--font-size-16) / var(--font-size-18) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      color: var(--unnamed-color-4d4d4d);
      text-align: center;
      opacity: 1;
      margin-top: 0.441rem;
    }
  }

  .services {
    height: auto;
    width: 41.71rem;
    display: flex;
    margin-top: 4.4rem;
    justify-content: space-evenly;
    align-items: baseline;

    @media only screen and (max-width: 600px) {
      margin-top: 3.122rem;
      display: flex;
      flex-wrap: wrap;
      width: 70%;

      .personalAdvice,
      .pharmacistAdvice {
        margin-bottom: 2.21rem;
      }

      .servicesText {
        flex-basis: 50%;

        img {
          width: 3.21rem;
          height: 3.14rem;
        }

        div {
          font: var(--font-style-normal) normal var(--font-weight-normal)
            var(--font-size-12) / var(--font-size-25) var(--font-family-primary);
          letter-spacing: var(--character-spacing-0);
          line-height: var(--font-size-16);
          color: var(--unnamed-color-4d4d4d);
          text-align: center;
          opacity: 1;
          margin-top: 0.5rem;
          width: 5.42rem;
        }
      }
    }
  }

  .joinClub {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.57rem;
    width: 20rem;
    margin-top: 6.35rem;
    background: var(--color-action) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
    opacity: 1;

    @media only screen and (max-width: 600px) {
      margin-top: 4.14rem;
      height: 3.42rem;
      width: 14.71rem;
      background: #f2415c 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 3px;
      opacity: 1;
    }

    .joinClubText {
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-25) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      color: var(--color-white);
      opacity: 1;
      text-decoration: none;

      @media only screen and (max-width: 600px) {
        font: var(--font-style-normal) normal var(--font-weight-bold)
          var(--font-size-20) / var(--line-spacing-30)
          var(--font-family-primary);
        letter-spacing: var(--character-spacing-0);
        opacity: 1;
      }
    }
  }

  .registeredCustomers {
    text-decoration: var(--decoration-underline);
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-16) / var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-decoration: underline;
    color: var(--color-primary);
    opacity: 1;
    margin-top: 2.3rem;

    .registeredCustomersLink {
      &:visited {
        color: var(--color-primary);
      }
    }
    @media only screen and (max-width: 600px) {
      font: var(--font-style-normal) normal var(--font-weight-normal)
        var(--font-size-16) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: left;
      text-decoration: underline;
      opacity: 1;
    }
  }

  .servicesText {
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-16) / 25px var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: center;
    color: var(--color-label);
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 8rem;

    div {
      margin-top: 1.285rem;
    }
  }
}
