.loginOrRegister {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33.64rem;
  height: 17.35rem;
  position: relative;

  .messageHeader {
    margin-top: 2rem;
    font: var(--font-style-normal) normal var(--font-weight-bold) 30px /
      var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: right;
    opacity: 1;
  }

  .messageDetails {
    margin-top: 0.758rem;
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-14) / 22px var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: center;
    opacity: 1;
  }

  .loginLink {
    background: var(--color-action) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    font: var(--font-style-normal) normal var(--font-weight-bold)
      var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-align: center;
    color: var(--color-white);
    width: 12.28rem;
    height: 3.14rem;
    margin-top: 2.785rem;
    border: none;
    cursor: pointer;
    outline: none;
  }

  .closeModalButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;

    @media only screen and (max-width: 320px) {
      right: 5.5rem;
      top: 0.5rem;
    }

    @media only screen and (min-width: 360px) and (max-width: 400px) {
      right: 3.7rem;
      top: 0.5rem;
    }

    @media only screen and (min-width: 400px) and (max-width: 600px) {
      right: 3rem;
      top: 0.5rem;
    }
  }
}
