.header {
  padding-top: 4.57rem;

  @media only screen and (max-width: 600px) {
    padding-top: 1.147rem;

    img {
      @media only screen and (max-width: 600px) {
        height: 1.6rem;
        width: 6.83rem;
      }
    }
  }
}

.progressHeader {
  padding-top: calc(6.82vh);
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
    padding-top: 1.147rem;
    margin-left: 1rem;
    margin-right: 1rem;

    & > *:last-child {
      flex-basis: 100%;
      order: -1;
      margin: 0 auto;
      justify-content: center;
    }
  }

  img {
    padding-left: 10.52%;

    @media only screen and (max-width: 600px) {
      height: 1.6rem;
      width: 6.83rem;
      padding-left: 0;
    }
  }

  .progressBar {
    display: flex;
    margin-right: 10.52%;
    width: 60%;

    @media screen and (max-width: 600px) {
      width: 100%;
      margin-top: 1.744rem;
      justify-content: space-between;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .step1,
    .step2,
    .step3,
    .step4 {
      width: 25%;

      .progressBarText {
        font: var(--font-style-normal) normal var(--font-weight-normal)
          var(--font-size-12) / var(--line-spacing-30)
          var(--font-family-primary);
        letter-spacing: var(--character-spacing-0);
        color: var(--color-label);
        text-align: right;
        color: var(--color-label);
        opacity: 0.3;

        @media only screen and (max-width: 600px) {
          width: 4.285rem;
          font: var(--font-style-normal) normal var(--font-weight-bold)
            var(--font-size-10) / var(--line-spacing-12)
            var(--font-family-primary);
          letter-spacing: var(--character-spacing-0);
          text-align: right;
          opacity: 1;

          .progressStep {
            display: block;
            flex-basis: 100%;
            margin-bottom: 0.2rem;
          }
        }
      }

      .bar {
        width: 80%;
        height: 7px;
        border: 1px solid var(--color-header-step-inactive);
        border-radius: 8px;
        opacity: 0.3;

        @media only screen and (max-width: 600px) {
          margin-top: 0.2rem;
        }
      }

      &.addProgress {
        .progressBarText {
          color: var(--color-primary);
          opacity: 1;
        }

        .bar {
          border: 1px solid var(--color-primary);
          opacity: 1;
        }
      }

      &.done {
        .bar {
          background: var(--color-primary) 0% 0% no-repeat padding-box;
          opacity: 1;
        }
      }
    }
  }
}
