.clubMemberNotification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 600px) {
    padding-left: 3.57rem;
    padding-right: 3.57rem;
  }

  .notificationIcon {
    margin-top: 24.8vh;
    height: 3.19rem;
    width: 3.19rem;
    display: block;
  }

  .existingClubMemberMessageSummery {
    font: var(--font-style-normal) normal var(--font-weight-bold) 30px /
      var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: right;
    opacity: 1;
    margin-top: 2.65vh;

    @media only screen and (max-width: 600px) {
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-20) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      color: var(--color-primary);
    }
  }

  .existingClubMemberMessageDetails {
    margin-top: 1.11vh;
    font: var(--font-style-normal) normal var(--font-weight-normal) 20px/35px
      var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: center;
    opacity: 1;
    width: 33rem;

    @media only screen and (max-width: 600px) {
      font: var(--font-style-normal) normal var(--font-weight-normal)
        var(--font-size-12) / var(--font-size-20) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      width: auto;
    }
  }

  .finishProcessAndSubmit {
    margin-top: 5.51vh;
    background: var(--color-action) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid var(--color-action);
    opacity: 1;
    font: var(--font-style-normal) normal var(--font-weight-bold)
      var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-align: left;
    color: var(--color-white);
    padding: 0.78rem 3.07rem;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-14) / var(--line-spacing-14) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
    }
  }

  .quitFormLink {
    margin-top: 3.94vh;
    text-decoration: var(--decoration-underline);
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: left;
    text-decoration: underline;
    opacity: 1;
  }

  .goToHomepageLink {
    margin-top: 3.94vh;
    text-decoration: var(--decoration-underline);
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: center;
    text-decoration: underline;
    opacity: 1;

    @media only screen and (max-width: 600px) {
      text-decoration: var(--decoration-underline);
      font: var(--font-style-normal) var(--font-weight-normal)
        var(--font-size-14) / var(--line-spacing-14) var(--font-family-primary);
      color: var(--color-primary);
    }
  }
}
