.textInput {
  margin-bottom: 1rem;
  position: relative;
  width: 100%;

  label {
    display: block;
    margin-bottom: 2px;
    font: var(--font-style-normal) normal var(--font-weight-bold)
      var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    opacity: 1;

    .required {
      display: inline;
      margin-right: 4px;
      font: var(--font-style-normal) normal var(--font-weight-normal)
        var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: right;
      color: var(--color-action);
      opacity: 1;
    }
  }

  &.disabled {
    label {
      color: var(--color-inactive);
    }
  }

  &:focus-within label {
    color: var(--color-primary);
  }

  input {
    background: var(--color-white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--color-inactive);
    border-radius: 3px;
    opacity: 1;
    width: 100%;
    height: 1rem;
    padding: 0.75rem;

    &:focus {
      border-color: var(--color-primary);
    }
  }

  .errorMessage {
    font: var(--font-style-normal) normal var(--font-weight-normal) 1rem/1.83rem
      var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-align: right;
    color: var(--color-white);
    opacity: 1;
    position: absolute;
    left: 10px;
    bottom: 0.75rem;
    font-weight: normal;

    .errorIcon {
      display: block;
      background-color: var(--color-action);
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      text-align: center;
      line-height: 1rem;
      cursor: pointer;
    }
  }

  &.hasError {
    input {
      border-color: var(--color-action);
    }
  }
}
