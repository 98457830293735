.buttonGroup {
  label {
    font: var(--font-style-normal) normal var(--font-weight-bold)
      var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    opacity: 1;
    margin-bottom: 2px;

    .required {
      display: inline;
      margin-right: 4px;
      font: var(--font-style-normal) normal var(--font-weight-normal)
        var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: right;
      color: var(--color-action);
      opacity: 1;
    }
  }

  &:focus-within label {
    color: var(--color-primary);
  }

  .toggleButtonGroup {
    background: var(--color-white) 0% 0% no-repeat padding-box;
    position: relative;
    opacity: 1;
    background-color: var(--color-white);
    display: flex;

    &::after {
      content: " ";
      display: block;
      border: 1px solid var(--color-separator);
      border-radius: 3px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 0;
    }

    .toggleButton {
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      padding: 0.2rem 0 0.2rem 0;
      border-radius: 3px;
      border-color: transparent;
      opacity: 1;
      z-index: 1;
      flex: 1;

      &:focus,
      &.selected {
        border-color: var(--color-primary);
        color: var(--color-primary);
        background-color: var(--color-white);
      }
    }
  }
}
