.selectInput {
  margin-bottom: 14px;
  position: relative;
  margin-top: 0;
  width: 100%;

  .selectConatainer {
    width: 100%;
    display: flex;
    outline: none;

    &:focus,
    :focus-visible,
    :focus-within,
    :hover {
      border-color: #0086a7;
    }

    .select {
      flex: 1;
      padding: 0.07rem 0;
      outline: none;

      .MuiSelect-select {
        padding-right: 0.5rem;

        &:focus,
        :focus-within,
        :hover {
          background-color: var(--color-white);
          border-color: var(--color-inactive);
        }
      }
    }

    .defultChoise {
      color: #bcbcbc;
    }
  }

  svg {
    top: calc(50% - 12px);
    color: rgba(0, 0, 0, 0.54);
    right: auto;
    left: 0;
    position: absolute;
    pointer-events: none;
    padding-left: 0.5rem;
  }

  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
    font: var(--font-style-normal) normal var(--font-weight-bold)
      var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    opacity: 1;

    .required {
      display: inline;
      margin-right: 4px;
      font: var(--font-style-normal) normal var(--font-weight-normal)
        var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: right;
      color: var(--color-action);
      opacity: 1;
    }
  }

  &:focus-within label {
    color: var(--color-primary);
  }

  .select {
    background-color: white;
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    opacity: 1;
    box-sizing: content-box;
  }

  &.disabled {
    label {
      color: var(--color-inactive);
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-primary);
    border-width: 1px;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-inactive);
  }

  .uniqueTooltip {
    font: var(--font-style-normal) normal var(--font-weight-normal) 12px/22px
      var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-align: right;
    color: #000000;
    opacity: 1;
    background-color: var(--color-white);
  }
}
