.userDetailsForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 3.944vh;
  width: 88.35%;
  text-align: right;

  @media only screen and (max-width: 600px) {
    text-align: center;
    align-items: center;
    margin-top: 0;
  }

  .idAndEmail,
  .address,
  .birthdateAndGender,
  .fullnameAndPhone,
  .optional,
  .optionalFurther {
    margin-top: 1.23vh;
    width: 100%;
    display: flex;

    @media only screen and (max-width: 600px) {
      flex-wrap: wrap;
    }
  }

  .birthdateAndGender {
    @media only screen and (max-width: 600px) {
      width: 100%;
    }

    .birthdate {
      width: 20.15%;

      @media only screen and (max-width: 600px) {
        flex-basis: 39%;
        margin-left: 1.142rem;
        margin-right: 2rem;
        label {
          text-align: right;
        }
      }
    }
    .gender {
      width: 20.15%;
      margin-right: 3.29%;

      @media only screen and (max-width: 600px) {
        flex-basis: 39%;
        margin-left: 2rem;
        margin-right: 0;
        label {
          text-align: right;
        }
      }
    }

    .birthdate {
      input {
        text-align: left;
      }
    }
  }

  .address {
    .city {
      width: 36.23%;

      @media only screen and (max-width: 600px) {
        flex-basis: 100%;
        margin-left: 2rem;
        margin-right: 2rem;
        label {
          text-align: right;
        }
      }
    }

    .streetAddress {
      width: 23.71%;
      margin-right: 3.16%;

      @media only screen and (max-width: 600px) {
        flex-basis: 50%;
        margin-left: 1.142rem;
        margin-right: 2rem;
        label {
          text-align: right;
        }
      }
    }

    .streetNumber {
      width: 9.35%;
      margin-right: 3.16%;

      @media only screen and (max-width: 600px) {
        flex-basis: 28%;
        margin-left: 2rem;
        margin-right: 0;
        label {
          text-align: right;
        }
      }
    }
  }

  .fullnameAndPhone {
    .fullname {
      width: 36.23%;

      @media only screen and (max-width: 600px) {
        flex-basis: 100%;
        margin-left: 2rem;
        margin-right: 2rem;
        label {
          text-align: right;
        }
      }
    }
    .phone {
      width: 36.23%;
      margin-right: 3.29%;

      @media only screen and (max-width: 600px) {
        flex-basis: 100%;
        margin-left: 2rem;
        margin-right: 2rem;
        label {
          text-align: right;
        }
      }
    }
  }

  .idAndEmail {
    .id {
      width: 36.23%;

      @media only screen and (max-width: 600px) {
        flex-basis: 100%;
        margin-left: 2rem;
        margin-right: 2rem;
        label {
          text-align: right;
        }
      }
    }
    .email {
      width: 36.23%;
      margin-right: 3.29%;

      @media only screen and (max-width: 600px) {
        flex-basis: 100%;
        margin-left: 2rem;
        margin-right: 2rem;
        label {
          text-align: right;
        }
      }
    }
  }

  .optional {
    .accreditedFullname {
      width: 36.23%;

      @media only screen and (max-width: 600px) {
        flex-basis: 100%;
        margin-left: 2rem;
        margin-right: 2rem;
        label {
          text-align: right;
        }
      }
    }

    .accreditedId {
      width: 36.23%;
      margin-right: 3.29%;

      @media only screen and (max-width: 600px) {
        flex-basis: 100%;
        margin-left: 2rem;
        margin-right: 2rem;
        label {
          text-align: right;
        }
      }
    }
  }

  .accreditedPhone {
    width: 36.23%;

    @media only screen and (max-width: 600px) {
      flex-basis: 100%;
      margin-left: 2rem;
      margin-right: 2rem;
      label {
        text-align: right;
      }
    }
  }

  .submitOrBack {
    margin-top: 7.14vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 600px) {
      margin-bottom: 2rem;
    }

    .back {
      text-decoration: var(--decoration-underline);
      font: var(--font-style-normal) normal var(--font-weight-normal)
        var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      color: var(--color-label);
      text-align: left;
      text-decoration: underline;
      opacity: 1;
    }

    .submitButton {
      width: 153px;
      background: var(--color-action) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border: none;
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: center;
      color: var(--color-white);
      opacity: 1;
      padding-top: 15px;
      padding-bottom: 15px;

      &:hover {
        background: var(--color-action) 0% 0% no-repeat padding-box;
      }

      @media only screen and (max-width: 600px) {
        height: 2.57rem;
        width: 8.785rem;
        font: var(--font-style-normal) normal var(--font-weight-bold)
          var(--font-size-14) / var(--line-spacing-14)
          var(--font-family-primary);
        letter-spacing: var(--character-spacing-0);
        text-align: left;
        opacity: 1;
      }
    }
    .MuiButton-root.Mui-disabled {
      background: var(--color-action-inactive);
      color: var(--color-white);
    }
  }

  .userDetailsSeparationLine {
    width: 75.75%;
    border-top: 1px solid var(--color-separator);
    opacity: 1;
    margin-top: 2.5vh;
  }
}
