html {
  font-size: 14px;
}

@media screen and (max-width: 1600px) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 10px;
  }
}
@media screen and (max-width: 600px) {
  html {
    font-size: 14px;
  }
}

body {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 600px) {
  body {
    background-color: var(--color-white) !important;
  }
}

@font-face {
  font-family: "Open Sans Hebrew";
  src: local("Open Sans Hebrew"),
    url(assets/fonts/OpenSansHebrew-Regular.ttf) format("opentype");
}

:root {
  /* Colors: */
  --color-label: #4d4d4d;
  --color-primary: #0086a7;
  --color-action: #f2415c;
  --color-action-inactive: rgba(242, 65, 92, 0.26);
  --color-white: #ffffff;
  --color-separator: #c9c9c9;
  --color-inactive: #909090;
  --color-footer-border: #d3d3d3;
  --color-header-step-inactive: #707070;

  /* Font/text values */
  --font-family-primary: Open Sans Hebrew;
  --font-style-normal: normal;
  --font-weight-normal: normal;
  --font-weight-bold: bold;
  --font-size-10: 0.714rem;
  --font-size-12: 0.85rem;
  --font-size-14: 1rem;
  --font-size-16: 1.15rem;
  --font-size-18: 1.285rem;
  --font-size-20: 1.42rem;
  --font-size-25: 1.785rem;
  --font-size-30: 2.14rem;
  --font-size-40: 2.85rem;
  --font-size-60: 60px;
  --character-spacing-0: 0px;
  --line-spacing-12: 0.85rem;
  --line-spacing-14: 1rem;
  --line-height-22: 1.57rem;
  --line-spacing-30: 2.14rem;
  --decoration-underline: underline;
}

/* Character Styles */
.sub-tittle-—-16pt {
  font-family: var(--font-family-primary);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-12);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-label);
}
.sub-button-—-16pt {
  font-family: var(--font-family-primary);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-16);
  line-height: var(--line-spacing-14);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-label);
  text-decoration: var(--decoration-underline);
}
.text-box-tittle-—-14pt {
  font-family: var(--font-family-primary);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-30);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-label);
}
.main-button-—-16pt {
  font-family: var(--font-family-primary);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-16);
  line-height: var(--line-spacing-14);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-label);
}
.main-tittle—-60pt {
  font-family: var(--font-family-primary);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-60);
  line-height: var(--line-spacing-30);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-label);
}
