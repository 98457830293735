.registerForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 275px;
  margin-top: 3.8vh;
  text-align: right;

  .submitButton {
    width: 10.92rem;
    margin-top: 7.14vh;
    background: var(--color-action) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: none;
    font: var(--font-style-normal) normal var(--font-weight-bold)
      var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    text-align: center;
    color: var(--color-white);
    opacity: 1;
    padding-top: 15px;
    padding-bottom: 15px;

    &:hover {
      background: var(--color-action) 0% 0% no-repeat padding-box;
    }

    @media only screen and (max-width: 600px) {
      height: 2.57rem;
      width: 8.785rem;
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-14) / var(--line-spacing-14) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: left;
      opacity: 1;
    }
  }

  .MuiButton-root.Mui-disabled {
    background: var(--color-action-inactive);
    color: var(--color-white);
  }
}
