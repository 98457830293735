.layout {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;

  @media only screen and (max-width: 600px) {
    height: auto;
    min-height: 100vh;
    overflow: auto;
  }

  .layoutMain {
    width: 50%;
    position: relative;

    @media only screen and (max-width: 600px) {
      width: 100%;
      height: auto;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }

    .layoutMainContent {
      height: calc(100vh - 3.57rem);
      overflow-y: auto;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 600px) {
        height: auto;
        flex: 1;
      }
    }
  }

  .layoutGallery {
    width: 50%;

    @media only screen and (max-width: 600px) {
      display: none;
      width: 0%;
    }
  }
}
