.loginPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loginGreetingText {
    font: var(--font-style-normal) normal var(--font-weight-bold) 30px /
      var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: center;
    color: var(--color-label);
    opacity: 1;
    margin-top: 12.46rem;

    @media only screen and (max-width: 600px) {
      font: var(--font-style-normal) var(--font-weight-bold) var(--font-size-20) /
        var(--line-spacing-30) var(--font-family-primary);
      color: var(--color-label);
      text-align: center;
      opacity: 1;
      margin-top: 7.53rem;
    }
  }

  .loginConfirmDetailsText {
    margin-top: 0.798rem;
    font: var(--font-style-normal) normal var(--font-weight-normal)
      var(--font-size-16) / 25px var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: center;
    opacity: 1;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
}
