.multiInputComplete {
  font-size: 1rem;
  width: 100%;

  .uniqueTooltip {
    background-image: transparent
      url("../../../../assets//images/tooltipIcon.svg") 0% 0% no-repeat
      padding-box;
    width: 1rem;
    height: 1rem;
  }

  label {
    font: var(--font-style-normal) normal var(--font-weight-bold)
      var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    text-align: right;
    opacity: 1;
    display: flex;
    justify-content: space-between;

    &:focus,
    :focus-within,
    :active {
      color: var(--color-primary);
    }
  }

  input {
    &:focus,
    :focus-within,
    :active {
      color: var(--color-primary);
    }
  }

  .MuiSelect-select.MuiSelect-select {
    background: var(--color-white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--color-inactive);
    border-radius: 3px;
    width: 100%;
    opacity: 1;
    height: 1.5rem;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 1rem;
  }
}
.MuiCheckbox-colorSecondary.Mui-checked {
  svg {
    color: var(--color-primary);
  }
}

.MuiListItemText-root {
  font: var(--font-style-normal) normal var(--font-weight-normal)
    var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-label);
  text-align: right;
  opacity: 1;
}

.MuiMenuItem-root {
  height: 1.5rem;

  .MuiTypography-displayBlock {
    text-align: right;
  }
}
.MuiMenuItem-root .MuiTypography-displayBlock {
  font: var(--font-style-normal) normal var(--font-weight-normal)
    var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-label);
  text-align: right;
  opacity: 1;
}

.MuiPaper-root {
  padding: 0.5rem 0;
}
