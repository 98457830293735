.policyText {
  font: var(--font-style-normal) normal var(--font-weight-normal)
    var(--font-size-14) / var(--font-size-20) var(--font-family-primary);
  letter-spacing: var(--character-spacing-0);
  text-align: right;
  color: var(--color-label);
  opacity: 1;

  .useTerms {
    color: var(--color-primary);
  }
}
