.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.57rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px solid var(--color-footer-border);
  background-color: var(--color-white);

  @media only screen and (max-width: 600px) {
    border-top: none;
    flex-wrap: wrap;
    height: 5rem;
    position: static;
    bottom: 0;

    & > *:last-child {
      flex-basis: 100%;
      order: -1;
      margin: 0 auto;
      justify-content: center;
    }
  }

  font: var(--font-style-normal) normal var(--font-weight-normal)
    var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-label);

  .qustionsAndAnswers {
    text-decoration: var(--decoration-underline);
    font: var(--font-style-normal) normal var(--font-weight-bold)
      var(--font-size-14) / var(--line-spacing-30) var(--font-family-primary);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-label);
    align-items: center;

    .faq {
      background-color: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .footerItem,
  .qustionsAndAnswers {
    display: flex;
    align-items: center;
    align-content: center;
    img {
      margin-left: 8px;
    }
    .footerValue {
      margin-right: 2px;
    }
    .atSignIcon {
      margin-bottom: 2px;
    }
  }

  @media only screen and (max-width: 600px) {
    .footerText {
      display: none;
    }
    .footerValue {
      font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-normal) 12px / var(--unnamed-line-spacing-30)
        var(--unnamed-font-family-open-sans-hebrew);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-4d4d4d);
      text-align: left;
      opacity: 1;
    }

    .footerItem {
      .atSignIcon {
        margin-bottom: 5px;
      }
    }
  }
}
