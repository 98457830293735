.additionalDetailsForm {
  width: 54.48%;

  @media only screen and (max-width: 600px) {
    width: auto;
  }

  .licenseNumberinput {
    margin-top: 3.94vh;
  }

  .licenseDetails {
    display: flex;
    align-items: center;
    margin-top: 2.23vh;
    justify-content: space-evenly;

    @media only screen and (max-width: 600px) {
      flex-wrap: wrap;
    }

    .careGiverFullname {
      width: 35.89%;

      @media only screen and (max-width: 600px) {
        flex-basis: 100%;
      }
    }

    .licenseExpirationDate {
      width: 32.7%;
      margin-right: 5.34%;

      @media only screen and (max-width: 600px) {
        flex-basis: 55%;
        margin-right: 0;
      }

      input {
        text-align: left;
      }
    }

    .allowedWeight {
      width: 20.72%;
      margin-right: 1.785rem;

      @media only screen and (max-width: 600px) {
        flex-basis: 35.5%;
      }
    }
  }

  .medicalInfo {
    display: flex;
    align-items: center;
    margin-top: 2.23vh;

    @media only screen and (max-width: 600px) {
      flex-wrap: wrap;
    }

    .medicalField {
      width: 48.5%;

      @media only screen and (max-width: 600px) {
        flex-basis: 100%;
      }

      select {
        padding-right: 0.5rem;
        align-content: center;
        text-align: center;
      }
    }

    .usage {
      width: 45.94%;
      margin-right: 5.34%;

      @media only screen and (max-width: 600px) {
        flex-basis: 100%;
        margin-right: 0;
      }
    }
  }

  .additionalDetailsSeparationLine {
    width: 100%;
    border-top: 1px solid var(--color-separator);
    opacity: 1;
    margin-top: 2.5vh;
  }

  .otherTextArea {
    margin-top: 2.5vh;
  }

  .submitOrBack {
    margin-top: 7.14vh;
    width: 150%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 600px) {
      width: auto;
    }

    .back {
      text-decoration: var(--decoration-underline);
      font: var(--font-style-normal) normal var(--font-weight-normal)
        var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      color: var(--color-label);
      text-align: left;
      text-decoration: underline;
      opacity: 1;

      @media only screen and (max-width: 600px) {
        text-decoration: var(--decoration-underline);
        font: var(--font-style-normal) normal var(--font-weight-normal)
          var(--font-size-12) / var(--line-spacing-14)
          var(--font-family-primary);
        letter-spacing: var(--character-spacing-0);
      }
    }

    .submitButton {
      width: 10.82rem;
      background: var(--color-action) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border: none;
      font: var(--font-style-normal) normal var(--font-weight-bold)
        var(--font-size-16) / var(--line-spacing-14) var(--font-family-primary);
      letter-spacing: var(--character-spacing-0);
      text-align: center;
      color: var(--color-white);
      opacity: 1;
      padding-top: 1.07rem;
      padding-bottom: 1.07rem;

      @media only screen and (max-width: 600px) {
        width: 8.785rem;
        height: 2.57rem;
        font: var(--font-style-normal) normal var(--font-weight-bold)
          var(--font-size-14) / var(--line-spacing-14)
          var(--font-family-primary);
        letter-spacing: var(--character-spacing-0);
        text-align: left;
        opacity: 1;
      }

      &:hover {
        background: var(--color-action) 0% 0% no-repeat padding-box;
      }
    }
    .MuiButton-root.Mui-disabled {
      background: var(--color-action-inactive);
      color: var(--color-white);
    }
  }
}
